import select2 from 'select2';
select2();

// select リストを検索したりできる
export function initSelect2() {
  $('.select2').each(function(){
    var placeholder = $(this).attr('placeholder');
    var width = $(this).attr('width');
    var options = {
      placeholder: placeholder,
      allowClear: true
    }
    if (width) options['width'] = width;
    $(this).select2(options);
  });
}
