// フォームの入力途中で離脱しようとした場合に警告する
export function initUnsavedUnloadConfirm() {
  const selector = '.unsaved_unload_confirm';
  if ($(selector).length) {
    $(selector).data('changed', false);
    $(selector).find('input,textarea,select').on('change', function() {
      $(selector).data('changed', true);
    });
    if ($('#error_explanation').length) {
      $(selector).data('changed', true);
    }
    $(selector).on('submit', function(){
      $(window).off('beforeunload.confirm');
    });
    $(window).on('beforeunload.confirm', function(e) {
      if ($(selector).data('changed')) {
        return '保存されていないデータがあります。移動していいですか？';
      }
    });
  }
}

// 保存してから操作を促す
export function initUnsavedSaveConfirm() {
  const selector = '.unsaved_save_confirm';
  $(selector).on('click', function(){
    return runUnsavedSaveConfirm($(this));
  });
}

export function runUnsavedSaveConfirm($form) {
  if ($form.closest('form').data('changed')) {
    alert('保存されていないデータがあります。保存してからボタンを押してください。');
    return false;
  } else {
    return true;
  }
}

export function hasUnsavedChanges() {
  const selector = '.unsaved_unload_confirm';
  console.log('hasUnsavedChanges');
  if ($(selector).data('changed')) {
    return true;
  } else {
    return false;
  }
}

// CSVダウンロード時間がかかる警告
export function initConfirmCsvDownload() {
  $(".confirm_csv_download").attr('data-confirm', 'CSVダウンロード処理を実行します。よろしいですか ?\n時間がかかる重い処理のため、実行後ダウンロードが開始するまでしばらくお待ちください。');
}
