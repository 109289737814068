import select2 from 'select2';
select2();

export function setMunicipalities(target_id, prefecture_id, municipality_id) {
  if (!prefecture_id) return;
  return $.ajax({
    type: 'GET',
    url: '/api/municipalities.json',
    data: {
      'q[prefecture_id_eq]': prefecture_id
    }
  }).done(function(data){
    var html = '';
    data['municipalities'].unshift({id: '', name: '（市区町村）'})
    for (var i = 0; i < data['municipalities'].length; i++) {
      html += '<option value="' + data['municipalities'][i].id + '">' + data['municipalities'][i].name + ' (' + data['municipalities'][i].name_kana + ')' + '</option>';
    }
    $(target_id).html(html);
    if (municipality_id) {
      $(target_id).val(municipality_id);
      $(target_id).trigger('change');
    }
  });
}

export function initMunicipalitiesSelect(selector) {
  $(selector).select2({
    placeholder: $(selector).attr('placeholder'),
    allowClear: true,
    minimumInputLength: 2,
    language: {
      'noResults': function(){ return "見つかりません。";},
      'inputTooShort': function(e){
        var t = e.minimum - e.input.length;
        n = t + " 文字以上入力してください";
        return n
      },
    },
    escapeMarkup: function (markup) { return markup; },
    ajax: {
      url: "/api/municipalities.json",
      dataType: "json",
      type: "GET",
      quietMillis: 1000,
      data: function(params) {
        return { 'q[name_or_name_kana_or_prefecture_name_cont]': params.term };
      },
      processResults: function (data) {
        var results = [];
        $.each(data.municipalities, function (index, municipality) {
            results.push({
                id: municipality.id,
                text: municipality.prefecture_name + municipality.name + '（' + municipality.name_kana + '）'
            });
        });
        return {
            results: results
        };
      }
    }
  });
}
