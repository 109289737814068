import { setMunicipalities } from "./municipalities";

// 郵便番号を入れると都道府県と市区町村が自動選択される仕組み
export function initPostcodeInput() {
  $(document).on('keyup', '.set_area_by_postcode', function(event){
    var postcode = $(this).val();

    if (!postcode.match(/[0-9]{7}/)) return;
    if (postcode == $(this).attr('postcode')) return;
    $(this).attr('postcode', postcode);

    var field = $(this).attr('id').replace(/funeral_issue_(\w+?)_postcode/, '$1');
    $.ajax({
      type: 'GET',
      url: '/api/postcodes.json',
      data: {
        'q[postcode_eq]': postcode
      }
    }).then(function(data){
      var postcodes = data['postcodes'];
      if (postcodes.length == 0) {
        alert('郵便番号に一致する住所が見つかりませんでした。');
      } else if (postcodes.length >= 1) {
        if (postcodes.length >= 2) {
          alert('郵便番号に一致する住所が(' + postcodes.length + '件)見つかりました。住所を確認してください。');
        }
        var prefecture = '#funeral_issue_' + field + '_prefecture_id';
        $(prefecture).val(postcodes[0]['prefecture_id']);
        $(prefecture).trigger('change.select2');

        setMunicipalities('#funeral_issue_' + field + '_municipality_id', postcodes[0]['prefecture_id'], postcodes[0]['municipality_id'])
        $('#funeral_issue_' + field + '_address').val(postcodes[0]['city_name']);
      }
    });
  });
}
