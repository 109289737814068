import { setMunicipalities } from "./municipalities";
import { initUnsavedUnloadConfirm, initUnsavedSaveConfirm } from './confirm';

// 都道府県を選択すると、市区町村の選択リストがその都道府県のものに変わる仕組み
export function initPrefecturesSelect() {
  $(document).on('change', '.prefectures_select', function(event, municipality_id){
    var target_id = $(this).data('municipalities-set-target-id');
    var prefecture_id = $(this).val();
    setMunicipalities(target_id, prefecture_id, municipality_id || $(target_id).val());
  });
}

// 市区町村の選択リストを都道府県のものに更新する
export function initMunicipalitiesSelect() {
  const defers = [];
  $('.prefectures_select').each(function(){
    var target_id = $(this).data('municipalities-set-target-id');
    var prefecture_id = $(this).val();
    defers.push(setMunicipalities(target_id, prefecture_id, $(target_id).val()));
  });
  // setMunicipalitiesでAPIコール後、changeが実行されるため、
  // この処理が完全に実行されてからフォームの入力途中離脱防止を初期化しないと何も変更してないのにアラートが出てしまう
  $.when.apply($, defers).done(function() {
    initUnsavedUnloadConfirm();
    initUnsavedSaveConfirm();
  });
}
