import 'moment'
import 'moment/locale/ja'
// NOTE: eonasdan-bootstrap-datetimepickerのバージョンを上げる場合、vendorから下記ファイルを削除する。
//       現在のバージョンだとjQueryで廃止されるblur()が使用されているため
//       ファイルをvendorにコピーして該当コードをリプレイスしている
require('../vendor/eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker');

export function initDatetimepicker() {
  const icons = {
    time: 'fa fa-clock-o',
    date: 'fa fa-calendar',
    up: 'fa fa-chevron-circle-up',
    down: 'fa fa-chevron-circle-down',
    next: 'fa fa-chevron-circle-right',
    previous: 'fa fa-chevron-circle-left'
  };
  $('.datepicker').datetimepicker({
    format: 'YYYY/MM/DD',
    icons: icons
  });  
}
