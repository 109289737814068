import { initPostcodeInput } from './postcode';
import { initDatetimepicker } from './datetimepicker';
import { initSelect2 } from './select2';
import { initMunicipalitiesSelect, initPrefecturesSelect } from './funeral_issues';
import { initAutosizeTextarea } from './autosize';
import { initConvertStatic } from './convert_static';
import { initDisallowEnterkeySubmit, initSetDefaultTextFromPlaceholderWhenOnfocus, initSaveFormShownValue } from './form';
import { initConfirmCsvDownload } from './confirm';
import { initFancybox } from './fancybox';
import { initTooltip } from './bootstrap';
import { initSmoothScroll } from './smooth_scroll';
import { initNotification } from './notification';
import { initOnlyOneChecked } from './checkbox';

$(function(){
  initSaveFormShownValue();
  initMunicipalitiesSelect();
  initConvertStatic();
  initSelect2();
  initPostcodeInput();
  initPrefecturesSelect();
  initDisallowEnterkeySubmit();
  initDatetimepicker();
  initFancybox();
  initConfirmCsvDownload();
  initTooltip();
  initSetDefaultTextFromPlaceholderWhenOnfocus();
  initAutosizeTextarea();
  initSmoothScroll();
  initNotification();
  initOnlyOneChecked();
});
