export function initConvertStatic() {
  // あるフォーム要素を 入力可能 / 表示のみ のどちらでも共通のビューファイルで実現したくて
  // 表示のみのフォーム要素は、static 的なクラスを持たせると表示用に切り替わるようにしている
  $('.convert_static, [static=true]').each(function(){
    $(this).css('display', 'none');
    $(this).find('input[type=text], input[type=number], input[type=email], input[type=url], textarea, select').each(function(){
      var text;
      switch ($(this).prop('tagName')) {
        case 'INPUT':
          text = $(this).attr('value');
          break;
        case 'SELECT':
          text = $(this).find('option:selected').text();
          break;
        case 'TEXTAREA':
          text = $(this).text().replace(/[\r\n]/g, '<br>');
          break;
      }
      var id = $(this).attr('id');
      $(this).replaceWith('<p class="form-control static" id="' + id + '">' + (text || '　') + '</p>');
    });
    $(this).find('input[type=radio], input[type=checkbox]').each(function(){
      var labeltag = $(this).parent();
      var label = labeltag.text();
      var class_text = $(this).attr('name').replace(/[\[\]]/g, '_').replace(/_$/, '');
      var text_status = $(this).prop('checked') ? 'checked' : 'text-muted';;
      var icon_class;
      switch ($(this).attr('type')) {
        case 'radio':
          icon_class = $(this).prop('checked') ? 'fa-dot-circle' : 'fa-circle';
          break;
        case 'checkbox':
          icon_class = $(this).prop('checked') ? 'fa-check-square' : 'fa-square';
          break;
      }
      labeltag.replaceWith(`<span class="${text_status} ${class_text}"><i class="far ${icon_class}"></i>${label}</span>`);
    });
    $(this).find('label').removeAttr('title');
    $(this).find('input[type=hidden]').remove();
    $(this).css('display', 'block');
  });
}
