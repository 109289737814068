export function initSmoothScroll() {
  $('a[href^="#"].smooth_scroll').on('click', function() {
    var speed = 500;
    var href = $(this).attr('href');
    var target = $(href);
    var offset = $('.navbar-fixed-top').length ? $('.navbar-fixed-top').outerHeight() : 0;
    var position = target.offset().top - offset;
    //スムーススクロール
    $('html, body').animate({scrollTop: position}, speed, 'swing');
    return false;
  });
}
