export function initOnlyOneChecked() {
  let inputNames = []
  $.each($('.js-only-one-checked'), function(_index, inputElement) {
    inputNames.push(inputElement['name'])
  })
  inputNames = Array.from(new Set(inputNames)); // 重複削除
  inputNames.forEach(inputName => {
    const selector = `input[name="${inputName}"]`
    const form = $(selector).closest('form')
    // チェックを外した場合、paramsにキーがなくなり更新されないため追加。
    $('<input>').attr({ type: 'hidden', name: inputName}).prependTo('form');

    $(selector).on('click', function() {
      if ($(this).prop('checked')){
        $(selector).prop('checked', false);
        $(this).prop('checked', true);
      }
    })
  })
}
