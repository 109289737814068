// enter キーでは submit できないようにする
export function initDisallowEnterkeySubmit() {
  $('.disallow_enterkey_submit').on("keypress", "input,select", function(event) {
    return event.which !== 13;
  });
}

// placeholder の内容を初期値とするテキストエリアにする
export function initSetDefaultTextFromPlaceholderWhenOnfocus() {
  $(".set_default_text_from_placeholder_when_onfocus").on('focus', function(){
    if ($(this).val() == '') {
      $(this).val($(this).attr('placeholder'));
    }
  })
  $(".set_default_text_from_placeholder_when_onfocus").on('blur', function(){
    if ($(this).val() == $(this).attr('placeholder')) {
      $(this).val('');
    }
  })
}

// フォーム表示時のvalueを属性に保持する
export function initSaveFormShownValue() {
  $('.save_form_shown_value').each(function(){
    let name = $(this).attr('name');
    let value = getInputValue(name);
    // console.log('save_form_shown_value', name, value);
    $(this).data('form_shown_value', value);
  });
}

// input name を渡すと、値を取得する関数
export function getInputValue(name) {
  let value;
  const selector = '[name=' + escapeSelector(name) + '][type!=hidden]'
  switch ($(selector).prop('tagName')) {
    case 'INPUT':
      switch ($(selector).attr('type')) {
        case 'text':
        case 'email':
          value = $(selector).val();
          break;
        case 'radio':
        case 'checkbox':
          value = $(selector + ':checked').val();
          break;
      }
      break;
    case 'SELECT':
      value = $(selector).find('option:selected').val();
      break;
    case 'TEXTAREA':
      value = $(selector).val();
      break;
  }
  return value;
}

export function escapeSelector(selector) {
  return selector.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}
