// NOTE: bootstrap-sassのバージョンを上げる場合、vendorから下記ファイルを削除する。
//       現在のバージョンだとjQueryで廃止される$.isFunctionが使用されているため
//       ファイルをvendorにコピーして該当コードをリプレイスしている
import '../vendor/bootstrap-sass/assets/javascripts/bootstrap/tooltip';

// tooltip があるラベルに ? アイコンをつける
export function initTooltip() {
  $('label[title],th[title]').each(function(){
    $(this).tooltip();
    $(this).html($(this).text() + '<i class="fa fa-question" style="font-size: 12px"></i>');
  });
}
