export function initNotification() {
  // クライアント側管理画面でお知らせをモーダル上で既読にする
  $(document).on('click', '.js-read-notification-btn', function(event) {
    event.preventDefault();

    const formId = $(this).data('formId');
    const modalId = $(this).data('modalId');
    const form = $(`#${formId}`)
    $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: form.serialize()
    })
    .then(
      function(res) {
        $('#js-notification-area').replaceWith(res)
        notificationCount = $('#js-notification-area').data('notification-count')
        if (notificationCount != undefined) {
          $('#js-notification-count').text(notificationCount)
        } else {
          $('#js-notification-count').text(0)
        }
        _closeModal(modalId)
        alert('お知らせを既読にしました。')
      },
      function(error) {
        _closeModal(modalId)
        alert('既読処理に失敗しました。時間を置いて再度お試しください。')
        console.log(`status: ${error.status}, statusText: ${error.statusText}`)
      }
    )
  })
}

function _closeModal(modalId) {
  $(`#${modalId}`).find('.is-close').trigger('click');
}
